import React from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';

function getYouTubeVideoId(url) {
  // Regular expression to find YouTube video ID from various URL formats
  var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  // Extract video ID using match and return
  var match = url.match(regExp);
  return (match && match[1]) ? match[1] : null;
}

const SectionGallery = props => {
  const { listing, variantPrefix } = props;
  const videos = [];
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  const videoUrl = listing.attributes.publicData.youtube_video_url;
  if (videoUrl) videos.push(getYouTubeVideoId(videoUrl));

  return (
    <section className={css.productGallery} data-testid="carousel">
      <ListingImageGallery
        images={images}
        videos={videos}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
      />
    </section>
  );
};

export default SectionGallery;
